

































import Vue from 'vue'
// @ts-ignore
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
// @ts-ignore
import corpDetailsMixin from '@utils/mixins/addy-plus-corporation-details-from-side-menu-mixin.js'
import Permission from '@views/addy_plus/base/Permission.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'
import Tabs from '@views/addy_plus/corporations/Tabs.vue'
import WalletCard from '@views/dashboard/WalletCard.vue'
import WalletActions from './components/WalletActions.vue'
import LinkedAccount from '@views/wallet/landing/LinkedAccount.vue'
import Transactions from '@views/wallet/landing/Transactions.vue'
import { fetchWalletDetails } from '@api/addy-plus/wallet'
import { IData, IMethods, IComputed } from '@/types/addy_plus/corporations/wallet/Index'

export default Vue.extend<IData, IMethods, IComputed>({
  mixins: [pageTitleMixin, corpDetailsMixin],
  components: {
    Permission,
    Breadcrumb,
    Tabs,
    WalletCard,
    WalletActions,
    LinkedAccount,
    Transactions,
  },
  data() {
    return {
      walletCardData: {
        walletBalance: 0,
        processingDepositTotal: 0,
      },
    }
  },
  computed: {
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  created() {
    this.getWalletDetails()
  },
  methods: {
    getWalletDetails() {
      fetchWalletDetails(this.corporationId).then((res) => {
        if (!res.success) return
        const { pendingCoinOrderAmount, walletBalance } = res.data
        this.walletCardData.walletBalance = walletBalance as number
        this.walletCardData.processingDepositTotal = pendingCoinOrderAmount as number
      })
    },
    addBankAccount() {
      this.$router.push(`${this.$route.path}/accounts/new`)
    },
  },
})
