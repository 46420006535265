














import Vue from 'vue'
import { ActionPayload, Action } from '@/types/addy_plus/corporations/wallet/WalletActions'

const ACTIONS: ActionPayload[] = [
  {
    name: 'Add funds',
    icon: 'transaction-type-deposit',
  },
  {
    name: 'Withdraw funds',
    icon: 'transaction-type-withdrawal',
  },
  {
    name: 'Distribute funds',
    icon: 'distribute-funds',
  },
  {
    name: 'Transfer funds',
    icon: 'transfer-funds',
  },
]

export default Vue.extend({
  data() {
    return {
      actions: Object.freeze(ACTIONS),
    }
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
  },
  methods: {
    onItemClick(action: Action) {
      const base = this.$route.path
      switch (Action[action]) {
      case 'Add':
        this.$router.push(`${base}/funds`)
        break
      case 'Withdraw':
        this.$router.push(`${base}/withdraw`)
        break
      case 'Distribute':
        this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet/distributeFunds`)
        break
      case 'Transfer':
        this.$router.push(`/addyPlus/${this.accountId}/corporations/${this.corporationId}/wallet/transferFunds`)
        break
      }
    },
  },
})
