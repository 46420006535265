export interface ActionPayload {
  name: string
  icon: string
}

export enum Action {
  Add,
  Withdraw,
  Distribute,
  Transfer,
}
